<template>

<b-container class="content">


  <!-- <div>
    <label for="setting">Update clientId: </label>
    <input
      id="setting"
      type="text"
      v-model="clientId"
      @input="updateLocalStorage"
    />
  </div>

  <div v-if="$store.state.clientId != null">
    <div> {{ $store.state.clientId }}</div>
    <b-button @click="removeClientId">Remove ClientId</b-button>
  </div> -->

  <div v-if="hasLaunched == false">
    <div class="feedback">
      <p>Welkom bij de 3D-weergave van het nieuwbouwproject! Klik op de knop hieronder om het model in AR te bekijken en op je tafel te projecteren.</p>
      <b-button @click="startAR" variant="primary" class="mt-3">Projecteer het model op je tafel</b-button>
      <div class="model-viewer-container">
    </div>
      <model-viewer 
              ref="modelViewer"
              src="https://dupdatatavjw5bpo7yfmc.blob.core.windows.net/models/Frontpage/maquette.glb"
              ios-src="https://dupdatatavjw5bpo7yfmc.blob.core.windows.net/models/Frontpage/maquette.usdz"
              ar 
              ar-modes="scene-viewer quick-look"
              shadow-intensity="1" 
              camera-controls 
              touch-action="pan-y"
              style="width: 300px; 
              height: 300px;">              
              <!-- Maak de AR-knop volledig onzichtbaar -->
              <button slot="ar-button" style="display: none;"></button>              
    </model-viewer>
    </div>

  </div>
  <div v-else>
    
    <div v-if="feedbackGiven == false" >

      <div class="feedback">
        <h1>Leuk dat je de AR-maquette hebt bekeken!</h1> 
        <div v-if="rating == null">
          <p>We zijn benieuwd wat je van de ervaring vond!</p> 
          <p>Kies een van de duimpjes om je mening te geven.</p>
        </div>
      </div>

      <div v-if="rating == null" class="mt-4 d-flex justify-content-center">
        <b-button 
          @click="giveFeedback(1)" 
          class="me-2" 
          style="width: 80px; height: 80px; padding: 0; background-color: transparent; border: none; font-size: 40px;">
          👍
        </b-button>
        <b-button 
          @click="giveFeedback(0)" 
          style="width: 80px; height: 80px; padding: 0; background-color: transparent; border: none; font-size: 40px;">
          👎
        </b-button>
      </div>

      <b-button @click="restartAR" variant="primary" class="mt-3">Ik wil de AR nog een keer zien</b-button>
    </div>
    
    <div v-else class="mt-3">
      <p>{{ feedback }}</p>
    </div>
  </div>

</b-container>

</template>
    
    
<script>
        
    export default {
      name: 'Test',
      data: function () {
        return {
          status: "status...",
          hasLaunched:false,
          feedback: "",
          sitelogId:"",
          feedbackGiven:false,
          clientId:null,
          rating: null
         };
      },
      computed:{    
      },
      watch: {
      },
      created:function(){    
      },
      mounted:function(){
        window.addEventListener('touchmove', this.preventScroll, { passive: false });
        this.$emit('setHeaderFooter', false);

      this.getSiteLog();

      },
     methods: {
        updateLocalStorage() {
        localStorage.setItem('clientId', this.clientId);
        this.$store.state.clientId = this.clientId;
      },
      preventScroll(event) {
        event.preventDefault(); // Voorkom de standaard scroll actie
      },
      removeClientId(){
        this.$store.state.clientId = null;
        localStorage.removeItem("clientId");
        
      },
      restartAR(){
          this.hasLaunched = false;
      },
      async getSiteLog(){
        var sitelogResult  = await this.$utils.getsiteLog("Frontpage/maquette.glb");

        this.hasLaunched = sitelogResult != null && sitelogResult.rating == null;
        this.sitelogId = sitelogResult.rowKey;
        this.rating = sitelogResult.rating;
        console.log(sitelogResult);
      },
      async startAR() {

        var sitelogResult  = await this.$utils.siteLog("Test view", "Frontpage/maquette.glb", "");
        this.sitelogId = sitelogResult.id;

        if(this.$refs.modelViewer.canActivateAR){
          this.$refs.modelViewer.activateAR();

          setTimeout(() => {
              this.hasLaunched = true;
          }, 2000);

        }
        else{
          this.status = "AR is niet beschikbaar op dit apparaat.";
          this.hasLaunched = true;
        }
    },
    async giveFeedback(rating) {
      this.feedback = "Bedankt voor je feedback!";
      this.feedbackGiven = true;

      var result = await this.$utils.updateRating(this.sitelogId, rating);
      //console.log(result);

    }


     },
      components: {
      },
      beforeDestroy() {
        // Verwijder de event listener bij het vernietigen van de component
    window.removeEventListener('touchmove', this.preventScroll);
      }
    }
</script>
    
<style scoped>
html, body {
  overflow: hidden; /* Voorkom scrollen */
  height: 100%; /* Zorg ervoor dat de body de volledige hoogte heeft */
  margin: 0; /* Verwijder standaard marges */
}

h1{
  font-size: 24px;
}

.feedback{
  text-align: left;
  padding: 20px;
}

.content{
  padding-top:20px;
}

.model-viewer-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
    
    