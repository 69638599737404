import Vue from 'vue';

export default class Store
{
  isDebug = false;
  baseUrl = '';
  //apiUrl = "http://localhost:7071/api";
  apiUrl = "https://dup-backend.azurewebsites.net/api";

  state = null;

  constructor()
  {

    this.state = Vue.observable({
      projects : null,
      isAdmin:false,
      role:null,
      clientId: null
    });

    this.fetchProjects();

  }

  async fetchProjects() 
  {
    if (!this.state.projects) {
      try {
        const response = await fetch(`${this.apiUrl}/GetProjects`);
        const data = await response.json();
        this.state.projects = data;

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    return this.state.projects;
  }
  
  async getClient()
  {

    try 
    {
        let url = `${this.apiUrl}/getclient`;

        const response = await fetch(url, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            clientId: localStorage.getItem("clientId"),
            isMobile : /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
            userAgent: navigator.userAgent
        })
      });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if(data.clientId != undefined ){
          localStorage.setItem('clientId', data.clientId);
          this.state.clientId = data.clientId;
          this.state.isAdmin = data.role == "admin";
          this.state.role = data.role;
        }
    } catch (error) {
        console.error('Error fetching client data:', error);
        throw error;
    }
}

  get projects() {
    return this.fetchProjects();
  }
  
}