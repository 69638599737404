import Vue from 'vue';
import './plugins/bootstrap-vue';
import App from './App.vue';
import router from './router';
import Store from './store';
import Utils from './utils';
import VueClipboard from 'vue-clipboard2';

Vue.config.productionTip = false;

Vue.use(VueClipboard);

// Store en utils beschikbaar maken
Vue.prototype.$store = new Store();
Vue.prototype.$utils = new Utils();

// Zorg ervoor dat we wachten op de clientId voordat we de app starten
async function initApp() {
  // Wacht op het ophalen van de clientId via getClient()
  await Vue.prototype.$store.getClient();

  // Start de Vue-app pas nadat de clientId is opgehaald
  var vueinstance = new Vue({
    router,
    render: function (h) { return h(App) }
  }).$mount('#app');

  vueinstance.$utils.bvToast = vueinstance.$root.$bvToast;
}

// Start de app pas na het ophalen van de clientId
initApp();

// Global router guard
router.beforeEach((to, from, next) => {
  // Reset de state voordat je naar een nieuwe pagina gaat
  Vue.prototype.$utils.resetState();
  next();
});
