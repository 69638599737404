<template>
<div id="app">
  <div v-if="showHeaderAndFooter">

    <b-navbar toggleable="md"  >      
            <b-navbar-brand href="#">            
        <router-link to="/"><img id="logo" src="images/amsterdam_logo.png"/></router-link>        
      </b-navbar-brand>

      <b-navbar-toggle target="nav_collapse" @click="toggleDropdown"></b-navbar-toggle>

      <b-collapse is-nav id="nav_collapse">

        <b-navbar-nav class="header">{{$route.name}}</b-navbar-nav>
        
        <b-navbar-nav class="ml-auto">

          <b-nav-item-dropdown ref="menuDropdown" right>
            <!-- Using button-content slot -->
            <template slot="button-content">
              <em>Menu</em>
            </template>
            <b-dropdown-item href="#/">Home</b-dropdown-item>
            <b-dropdown-item href="#/design">Design</b-dropdown-item>
            <b-dropdown-item href="#/models">Models</b-dropdown-item>
            <b-dropdown-item href="#/experiments">Experiments</b-dropdown-item>
            <b-dropdown-item href="#/svg">SVG</b-dropdown-item>
            <!-- <b-dropdown-item href="#/3dtile">3DTile</b-dropdown-item> -->
            <b-dropdown-item href="#/360files">360 Files</b-dropdown-item>
            <b-dropdown-item href="#/videos">Videos</b-dropdown-item>
            <b-dropdown-item v-if="$store.state.isAdmin || $store.state.role == 'metrics'" href="#/metrics">Metrics</b-dropdown-item>
            <b-dropdown-item href="#/findings">Findings</b-dropdown-item>
            <b-dropdown-item>
              <qr-code  :text="currentUrl" :size="100"></qr-code>
            </b-dropdown-item>
            <b-dropdown-item class="clientinfo" v-clipboard:copy="clientId" v-clipboard:success="onCopySuccess" v-clipboard:error="onCopyError">
              <div>ClientId: {{ formattedClientId }}</div>
              <p></p>
              <div>Role: {{ $store.state.role }}</div>
            </b-dropdown-item>

          </b-nav-item-dropdown>
        </b-navbar-nav>

      </b-collapse>
    </b-navbar>

  </div>

<hr>
   
<router-view class="main" :key="$route.fullPath" @setHeaderFooter="setHeaderFooter" />

<footer id="footer" v-if="showHeaderAndFooter">

  <b-container class="content">

    <h2>Contact</h2>
    <strong>Souhailla el Kassas</strong>
    <div>Product Owner</div>
    <div class="email">innovatie@amsterdam.nl</div> 

  </b-container>
</footer>

</div>
</template>

<script>

import VueQRCodeComponent from 'vue-qrcode-component'

export default {
  name: 'App',
  data: function () {
    return{
      name: "App",
      showHeaderAndFooter: true,
    }
  },
  components: {
    'qr-code': VueQRCodeComponent
  },
  created:function(){    
  },
  mounted:function(){   
  },
  methods: {

    onCopySuccess() {
      this.$utils.showToast('ClientId copied to clipboard', 'Success', 'success');
    },
    onCopyError() {
      alert('Kopiëren naar het klembord is mislukt!');
    },

    toggleDropdown() {
      this.$refs.menuDropdown.show();
    },
    setHeaderFooter(value) {
      this.showHeaderAndFooter = value;
    }
  },
  computed: {
    currentUrl() {
      return window.location.href
    },
    clientId() {
      return this.$store.state.clientId;
    },
    formattedClientId() {
      const clientId = this.$store.state.clientId || '';
      return clientId.replace(/-/g, ' ');
    }
  }
}

</script>

<style>

@font-face {
  font-family: "AvenirBold";
  src: local("AvenirBold"),
   url(./fonts/avenir/AvenirNextLTPro-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "AvenirDemi";
  src: local("AvenirDemi"),
   url(./fonts/avenir/AvenirNextLTPro-Demi.ttf) format("truetype");
}

@font-face {
  font-family: "AvenirHeavy";
  src: local("AvenirHeavy"),
   url(./fonts/avenir/AvenirNextLTPro-Heavy.ttf) format("truetype");
}

@font-face {
  font-family: "AvenirIt";
  src: local("AvenirIt"),
   url(./fonts/avenir/AvenirNextLTPro-It.ttf) format("truetype");
}

@font-face {
  font-family: "AvenirLight";
  src: local("AvenirLight"),
   url(./fonts/avenir/AvenirNextLTPro-Light.ttf) format("truetype");
}

@font-face {
  font-family: "AvenirMedium";
  src: local("AvenirMedium"),
   url(./fonts/avenir/AvenirNextLTPro-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "AvenirRegular";
  src: local("AvenirRegular"),
   url(./fonts/avenir/AvenirNextLTPro-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "AvenirThin";
  src: local("AvenirThin"),
   url(./fonts/avenir/AvenirNextLTPro-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "AvenirUltLt";
  src: local("AvenirUltLt"),
   url(./fonts/avenir/AvenirNextLTPro-UltLt.ttf) format("truetype");
}

h2{
  font-family: AvenirBold;
   font-size: 24px;
}

.dropdown-menu.show{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  margin-right: 20px;
  margin-top: 46px;
}


.header{
  font-family: AvenirBold;
  font-size: 20px;
}

#footer{
  margin-top:100px;
  background: #777;
  height:200px;
  padding: 30px;
  color:#fff;
  text-align: left;
}

#logo{
  margin-top:-10px;
  width: 120px;
  padding-top: 20px;
}

hr {
  margin-top:2px;
  border: 0;  
  background-color: #eee;
  height: 1px;
}

.qrspacer{
  height: 200px;
}

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: AvenirLight;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.title{
  font-family: AvenirBold;  
  padding-bottom: 20px;
  font-size: 18px !important;
}

.team{
  font-size: 16px;
  font-family: AvenirRegular;  
}

.clientinfo{
  width: 220px ;
  font-size: 11px;
  color: #999;
  font-style: italic;  
  margin-top:6px;
}

.clientinfo div{
  text-wrap:wrap !important;
}
</style>
