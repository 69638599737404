<template>

    <b-container class="content">

      <modelrow v-if="showModelDialog"
              :title="extractFileName(currentFile)"
              :model-src="`${currentDirectory}/${currentFile}`"
              :ios-src="iosSrc"
              :base-url="directoryData.baseUrl"
              :background-color="'#F5F5F5'"
              :qr-code-size="150"
              :short-code="currentShortCode"
              :project="currentProject"
              :add-model-viewer="true">
    </modelrow>

      <ul v-for="(directory, index) in directoryData.directories" :key="index" class="no-bullets">
        <h1>{{ directory.name }}</h1>

            <li v-for="(file, fileIndex) in directory.files.filter(file => file.fileName.endsWith('.glb'))" :key="fileIndex">          
            <b-button :variant="hasUsdzFile(directory.files, file.fileName) ? 'info' : 'secondary'" 
              @click="showModel(directory.name, 
                                file.fileName, 
                                file.shortCode, 
                                file.project,
                                getUsdzFileName(directory.files, file.fileName))">  
            {{ extractFileName(file.fileName) }} ({{ formatBytes(file.fileSize) }})
            </b-button>
          </li> 
        </ul>

    </b-container>
    
    </template>
    
    <script>
    
    import '@google/model-viewer';
    import VueQRCodeComponent from 'vue-qrcode-component'
    import ModelRow from './ModelRow.vue';

    export default {
      name: 'Models',
      data: function () {
        return{
          isAndroid: false,
          isIPhone: false,
          isMobile: false,
          modelDataUrl: '',
          timer:null,
          directoryData: Object,
          currentDirectory: '',
          currentFile: '',
          currentFile_iOS: '',
          currentShortCode: '',
          currentProject: ''
        }
      },
      computed:{
        showModelDialog() {   
          return this.currentDirectory != '' && this.currentFile != '' && this.currentShortCode != '';
        },
        iosSrc(){
          if(this.currentFile_iOS){
              return `${this.currentDirectory}/${this.currentFile_iOS}`;
          }
          else return null;
        }
      },
      watch: {
      },
      created:function(){    
      },
      mounted:function(){
        this.checkDevice();
        window.addEventListener('resize', this.checkDevice); 
        this.getModels();
      },
     methods: {
        async getModels() {
          try {
            const response = await fetch(`${this.$store.apiUrl}/GetModels`);
            const data = await response.json();
            this.directoryData = data;

            this.$store.baseUrl = data.baseUrl;
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        },
        hasUsdzFile(files, glbFileName) {
          const baseFileName = glbFileName.replace('.glb', '');
          return files.some(file => file.fileName === `${baseFileName}.usdz`);
        },
        getUsdzFileName(files, glbFileName) {
          const baseFileName = glbFileName.replace('.glb', '');
          const usdzFile = files.find(file => file.fileName === `${baseFileName}.usdz`);
          return usdzFile ? usdzFile.fileName : null;
        },
        extractFileName(fileUrl) {
          return decodeURIComponent(fileUrl.split('/').pop());
        },
        checkDevice() {
            this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            this.isAndroid = /Android/.test(navigator.userAgent);
            this.isIPhone = /iPhone/.test(navigator.userAgent);
        },
        isUSDZFile(filename) {
          return filename.toLowerCase().endsWith('.usdz');
        },
        showModel(directory, file, shortCode, project, usdzFileName){
          this.currentDirectory = directory;
          this.currentFile = file;
          this.currentFile_iOS = usdzFileName;
          this.currentShortCode = shortCode;
          this.currentProject = project;

          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });

        },
        formatBytes(bytes) {
          if (bytes === 0) return '0 Bytes';
          const k = 1024;
          const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB'];
          const i = Math.floor(Math.log(bytes) / Math.log(k));
          return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
        }
     },
      components: {
        'qr-code': VueQRCodeComponent,
        'modelrow': ModelRow
      },
      beforeDestroy() {
        window.removeEventListener('resize', this.checkDevice);
      }
    }
    </script>
    
    <style scoped>

    .content{
      text-align: left;
      font-family: AvenirMedium;
      
    }

    h1{
      font-size: 24px;
    }

    li{
      padding: 2px;
      padding-left: 10px;
    }

    .no-bullets {
      list-style-type: none; /* Verwijder de standaard bullets */
      padding: 0; /* Verwijder standaard padding */
    }
    .no-bullets li {
      margin: 5px 0; /* Optioneel: stel een marge in voor de lijstitems */
    }
      
    .row-item{
      font-size: 16px;
      padding: 40px;
      margin-left: auto;
      margin-right: auto;
    }
    
    .modelrow{
      margin-bottom: 100px;
    }
    </style>