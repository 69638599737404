<template>
    <div id="app">

      <div class="d-flex justify-content-center my-3">

          <b-dropdown :text="currentProject || 'Filter op project...'" class="custom-dropdown" variant="primary">
          <b-dropdown-item v-if="currentProject" @click="filterProject(null)">Reset filter</b-dropdown-item>
          <b-dropdown-divider v-if="currentProject" ></b-dropdown-divider>
          <b-dropdown-item 
            v-for="(project, index) in this.$store.state.projects"
            :key="index" 
            @click="filterProject(project.PartitionKey)">
            {{ project.PartitionKey }}
          </b-dropdown-item>
        </b-dropdown>

        <div class="mx-2">
          <label for="from-datepicker">From:</label>
          <b-form-datepicker 
            id="from-datepicker" 
            v-model="fromDate" 
            :max="toDate"
            class="mb-2"
            @input="onFromDateChange">   
          </b-form-datepicker>
          <b-button 
            v-if="fromDate" 
            variant="secondary" 
            class="ml-2" 
            @click="resetFromDate">
            Reset datum
          </b-button>
        </div>

        <div class="mx-2">
          <label for="to-datepicker">To:</label>
          <b-form-datepicker 
            id="to-datepicker" 
            v-model="toDate" 
            :min="fromDate"
            class="mb-2"
            @input="onToDateChange">
          </b-form-datepicker>
          <b-button 
            v-if="toDate" 
            variant="secondary" 
            class="ml-2" 
            @click="resetToDate">
            Reset datum
          </b-button>
        </div>

        <b-form-checkbox v-model="showAll" @change="getMetrics">Show All</b-form-checkbox>
        <b-form-checkbox v-model="hideInternal" @change="getMetrics">Hide internal</b-form-checkbox>
  
        <b-button variant="primary" @click="downloadCSV">
          Download CSV
        </b-button>

      </div>

        <table class="table table-striped">
      <thead>
        <tr>
          <th>Client ID</th>
          <th>Project</th>
          <th>Page</th>
          <th>Id</th>
          <th>Is Mobile</th>
          <th>Platform</th>
          <th>DateTime</th>
          <th>IP Info</th>
          <th>Rating</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="log in siteLogs" :key="log.ClientId">
          <td>{{ log.clientId }}</td>
          <th>{{ log.project }}</th>
          <td>{{ log.page }}</td>
          <td>{{ log.id }}</td>
          <td>{{ log.isMobile ? 'Yes' : 'No' }}</td>
          <th>{{ log.platform }}</th>
          <td>{{ log.dateTime }}</td>
          <td @click="showPopup(log.ipAddressInfo)">{{ formatIpInfo(log.ipAddressInfo) }}</td>
          <td>{{ log.rating }}</td>
        </tr>
      </tbody>
    </table>

    <b-modal v-model="isPopupVisible" title="Details van IP-informatie" hide-footer >

        <p><strong>City:</strong> {{ selectedLog.city }}</p>
        <p><strong>Country:</strong> {{ selectedLog.country }}</p>
        <p><strong>Region:</strong> {{ selectedLog.regionName }}</p>
        <p><strong>ZIP Code:</strong> {{ selectedLog.zip }}</p>
        <p><strong>ISP:</strong> {{ selectedLog.isp }}</p>
        <p><strong>Latitude:</strong> {{ selectedLog.lat }}</p>
        <p><strong>Longitude:</strong> {{ selectedLog.lon }}</p>
        <p><a :href="getMapLink(selectedLog)" target="_blank">Bekijk op Google Maps</a></p>
    </b-modal>
  
    </div>  
  </template>
  
  <script>
  import 'aframe';
  
  export default {
    name: 'App',
    data() {
      return {
        csvSeparator: ';',
        siteLogs: null,
        fromDate: null,
        toDate: null,
        currentProject: "",
        showAll: false,
        hideInternal: true,
        logs: [], // Je loggegevens hier
      isPopupVisible: false,
      selectedLog: {}
      };
    },
    mounted: function(){
        this.getMetrics();
    },
    methods: {
      getDate(datetimeString) {
        const [date] = datetimeString.split(' ');
        return date;
      },
      getTime(datetimeString) {
        const [, time] = datetimeString.split(' ');
        return time;
      },
      resetFromDate(){
        this.fromDate = null;
        this.getMetrics();
      },
      resetToDate(){
        this.toDate = null;
        this.getMetrics();
      },
      filterProject(project){
        if(this.currentProject != project){
          this.currentProject = project;
          this.getMetrics();
        }
      },
      onFromDateChange(date){
      //  console.log(date);
        this.getMetrics();

      },
      onToDateChange(date){
      //  console.log(date);
        this.getMetrics();
      },
      async getMetrics() {
        const filter = {
          clientId: this.$store.state.clientId,
          page: "",
          fromDate : this.fromDate,
          toDate: this.toDate,
          project: this.currentProject,
          hideInternal: this.hideInternal
        };
        try {

          const url = this.showAll
        ? `${this.$store.apiUrl}/getsitelogs?showall`
        : `${this.$store.apiUrl}/getsitelogs`;

          const response = await fetch(url, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(filter)
          });

          const data = await response.json();
          this.siteLogs = data;

        } catch (error) {
          console.error('Fout bij het opslaan van de gegevens:', error);
          }
      },
      downloadCSV() {
        const headers = ["Client ID", "Project", "Page", "Id", "Is Mobile", "Platform", "Date", "Time", "City", "Country", "Rating"];

        const rows = this.siteLogs.map(log => [
          log.clientId,
          log.project,
          log.page,
          log.id,
          log.isMobile ? 'Yes' : 'No',
          log.platform,
          this.getDate(log.dateTime),
          this.getTime(log.dateTime),
          this.getCityFromIpInfo(log.ipAddressInfo),
          this.getCountryFromIpInfo(log.ipAddressInfo),
          log.rating
          
        ]);

        let csvContent = "data:text/csv;charset=utf-8," 
          + headers.join(this.csvSeparator) 
          + "\n" 
          + rows.map(e => e.join(this.csvSeparator)).join("\n");

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "site_logs.csv");
        document.body.appendChild(link);

        link.click();
        document.body.removeChild(link);
    },
    formatIpInfo(ipAddressInfo) {
      try {
        const ipInfo = JSON.parse(ipAddressInfo);
        if(ipInfo == null ) return '';
        return `${ipInfo.city}, ${ipInfo.countryCode}`;
      } catch (error) {
        console.error("Error parsing IP address info:", error);
        return '';
      }
    },
    getCityFromIpInfo(ipAddressInfo){
      try {
        const ipInfo = JSON.parse(ipAddressInfo); 
        if(ipInfo == null ) return '';
        return ipInfo.city;
      } catch (error) {
        console.error("Error parsing IP address info:", error);
        return '';
      }
    },
    getCountryFromIpInfo(ipAddressInfo){
      try {
        const ipInfo = JSON.parse(ipAddressInfo);
        if(ipInfo == null ) return '';
        return ipInfo.country;
      } catch (error) {
        console.error("Error parsing IP address info:", error);
        return '';
      }
    },
    showPopup(ipAddressInfo) {
       this.selectedLog = JSON.parse(ipAddressInfo); // Verkrijg de IP-informatie in het geselecteerde log
       this.isPopupVisible = true; // Maak de popup zichtbaar
    },
    getMapLink(ipAddressInfo) {
      return `https://www.google.com/maps?q=${ipAddressInfo.lat},${ipAddressInfo.lon}`; // Link naar Google Maps
    }
  
    },
    computed: {
    }
    
  };
  </script>
  
  <style>

  .mb-2{
    width: 200px;
  }

  .btn-primary {
    color: #6c757d;
    background-color: #fff;
    border-color:#ccc;
    height: 38px;
    margin-top: 32px;
}

.custom-checkbox{
  margin-top: 38px;
  margin-right:6px;
}

.btn-primary:hover {
    background-color: #eee;
    color:#6c757d;
    border-color: #aaa;
}

.btn-primary.dropdown-toggle{
  background-color: #fff;
  color:#6c757d;
}

.show>.btn-primary.dropdown-toggle{
  background-color: #fff;
  color:#6c757d;
  border-color: #aaa;
}

.btn-group>.btn:focus{
  border-color: #aaa;
    box-shadow:none;
}


  </style>
  