<template>

<div class="container">

    <div v-if="this.$store.state.isAdmin" class="ontop">
      
      <b-button @click="setrotation">set rotation</b-button>
 
      <p>
        <b-dropdown :text="currentProject || 'Selecteer project...'" class="custom-dropdown" variant="primary">
              <b-dropdown-item 
                v-for="(project, index) in this.$store.state.projects"
                :key="index" 
                @click="updateProject($route.params.shortcode, project.PartitionKey)">
                {{ project.PartitionKey }}
              </b-dropdown-item>
      </b-dropdown>
      </p>

    </div>

    <!-- Text boven de knoppen -->
    <div v-if="showFeedback" class="bottom-container">
      <p class="text">Geef hieronder je feedback!</p> <!-- De tekst boven de knoppen -->
      <div class="button-group">
        <b-button 
          @click="giveFeedback(1)" 
          class="me-2" 
          style="width: 80px; height: 80px; padding: 0; background-color: transparent; border: none; font-size: 40px;">
          👍
        </b-button>
        <b-button 
          @click="giveFeedback(0)" 
          style="width: 80px; height: 80px; padding: 0; background-color: transparent; border: none; font-size: 40px;">
          👎
        </b-button>
      </div>
    </div>

    <a-scene v-if="fileurl != ''" @loaded="onSceneLoaded" xr-mode-ui="enabled: false">
      <a-entity camera look-controls ref="camera" ></a-entity>
      <a-sky :src="fileurl" :rotation="skyRotation"></a-sky>
    </a-scene>

  </div>


</template>

<script>
import 'aframe';

export default {
  name: 'App',
  data() {
    return {
      fileurl: '',
      skyRotation: '0 0 0',
      touchEnabled: true,
      currentProject:"",
      fileName: null,
      showFeedback:false,
      sitelogId:"",
      rating: null
    };
  },
  mounted: async function(){
    this.$emit('setHeaderFooter', false);
    await this.getUrl();

    this.fileName = this.$utils.extractFileName(this.fileurl);

    await this.$utils.siteLog("360 view", this.fileName, this.currentProject);
    await this.getSiteLog();
  },
  methods: {

    async updateProject(shortcode, project){
      this.currentProject = project;
        var success = await this.$utils.AddProjectToShortUrl(shortcode, project);
        if(success) this.$utils.showToast('Image set to Project: ' + project, 'Success', 'success');

    },
    onSceneLoaded() {
      this.getImageRotation();
      this.setTouchEnabled(this.$store.state.isAdmin);
    },
    setrotation(){
      var rot =this.$refs["camera"].getAttribute('rotation');
      var rotationString = `0 ${-rot.y} 0`;
      this.saveImageRotation(rotationString);
    },
    async getUrl() {
            await fetch(`${this.$store.apiUrl}/geturl/${this.$route.params.shortcode}`)
            .then(response => response.json())
            .then(data => {
              this.currentProject = data.project;
              this.fileurl = data.url;
            })
            .catch(error => {
              console.error('Fout bij het ophalen van gegevens:', error);
            });
    },
    async getImageRotation() {
    fetch(`${this.$store.apiUrl}/getimagerotation/${this.$route.params.shortcode}`)
        .then(response => {
            if (response.status === 404) {
              return;
            }
            return response.json();
        })
        .then(data => {
            if (data) {
                this.skyRotation = data.rotation;
            }
        })
        .catch(error => {
            console.error('Fout bij het ophalen van gegevens:', error);
        });
    }     
    ,
    async saveImageRotation(rotation) {
      const entity = {
          clientId: this.$store.state.clientId,
          PartitionKey: this.$route.params.shortcode,
          Rowkey: rotation
      };

      try {

          const response = await fetch(`${this.$store.apiUrl}/saveimagerotation`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(entity)
          });

          if(response.ok){
            const data = await response.text();
            this.$utils.showToast('Record saved successfully!', 'Success', 'success');
          }
      } catch (error) {
          console.error('Fout bij het opslaan van de gegevens:', error);
      }
},
    setTouchEnabled(enabled) {
      const cameraEl = this.$refs["camera"];
      cameraEl.setAttribute('look-controls', `touchEnabled: ${enabled}`);
    },
    async getSiteLog(){
        var sitelogResult  = await this.$utils.getsiteLog(this.fileName);

        //show when launched and rating not set.
        this.showFeedback = sitelogResult != null && sitelogResult.rating == null;
        this.sitelogId = sitelogResult.rowKey;
        this.rating = sitelogResult.rating;
     //   console.log(sitelogResult);
    },
    async giveFeedback(rating) {
      var result = await this.$utils.updateRating(this.sitelogId, rating);
     // this.feedback = "Bedankt voor je feedback!";
      this.showFeedback = false;
      
    }
  },
  computed: {
  },
  beforeDestroy() {
    this.$emit('setHeaderFooter', true);
  }
  
};
</script>

<style scoped>
/* #app {
  position: relative;
} */

.ontop {
  z-index: 10;
  color:#fff;
  position: absolute;
  left: 20px;
  top:20px;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.bottom-container {
  z-index: 10;
  margin-left:-15px;
  position: absolute;
  bottom: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;  /* Zorg ervoor dat de inhoud verticaal wordt uitgelijnd */
  justify-content: center;
  align-items: center;     /* Center de inhoud horizontaal */

  padding: 20px;            /* Padding rondom de inhoud */
}

.text {
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 4px 10px 4px 10px ;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 10px; /* Zorgt voor ruimte tussen de knoppen */
}



</style>
