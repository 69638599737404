<template>
  <div>
        <div>
          <b-button @click="centerModel">Center Model</b-button>
          <b-button @click="downloadTextures">Download textures</b-button>
            <b-button @click="updateMaterials">Realtime Shadows</b-button>
        </div>
      <div ref="threeContainer" class="three-container"></div>
    </div>


</template>

<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

export default {
  props: ['shortCode'],

  mounted() {

    this.initThreeJS();
    this.getUrl();
  },
  data: function () {
      return {
        planeSize:500,
        upscaleThreshold: 10,
        scene:null,
        camera:null,
        controls:null,
        sunlight: null,
        ambientLight: null,
        ambientForShadow: null,
      };
    },
  methods: {
    initThreeJS() {
      this.scene = new THREE.Scene();
      const scene = this.scene;

      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.shadowMap.enabled = true; // Enable shadows
      renderer.shadowMap.type = THREE.PCFSoftShadowMap; // Zachtere schaduwen
  //    renderer.shadowMap.type = THREE.BasicShadowMap; 
  //    renderer.shadowMap.type = THREE.VSMShadowMap; 

      this.$refs.threeContainer.appendChild(renderer.domElement);
      renderer.setClearColor(0xffffff, 1); // wit
      scene.background = new THREE.Color(0xffffff); // wit

      this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 10000);
      var camera = this.camera;
      camera.near = 1;
      //camera.far = 4000;
      camera.far = 400000;
      camera.position.set(0, 100, -300); // Camera positie
      camera.lookAt(0, 0, 0); // Richting van de camera

      // Zonlicht instellen
      this.sunlight = new THREE.DirectionalLight(0xffffff, 3);
      var sunlight = this.sunlight;
      sunlight.position.set(2000, 2000, 2000); // Verhoog de hoogte aanzienlijk om een groot gebied te verlichten
      sunlight.castShadow = true;
      sunlight.shadow.mapSize.width = 4096; // Verhoog de schaduwkwaliteit
      sunlight.shadow.mapSize.height = 4096;
      sunlight.shadow.bias = -0.0005;  
      sunlight.shadow.camera.left = -1000;    // Halve breedte van het gebied
      sunlight.shadow.camera.right = 1000;
      sunlight.shadow.camera.top = 1000;
      sunlight.shadow.camera.bottom = -1000;
      sunlight.shadow.camera.near = 1;       // Beginpunt van het schaduwvolume (dichtbij)
      sunlight.shadow.camera.far = 10000;     // Verre afstand voor het schaduwvolume
    
      this.ambientLight = new THREE.AmbientLight(0xffffff,3);
      this.ambientForShadow = new THREE.AmbientLight(0xffffff,1.5);
      scene.add(this.ambientLight);

      const floorGeometry = new THREE.PlaneGeometry(this.planeSize, this.planeSize);
      const floorMaterial = new THREE.MeshStandardMaterial({ 
          color: 0xffffff,
        //  roughness: 0.3
        });
      const floor = new THREE.Mesh(floorGeometry, floorMaterial);
      floor.name = "floor",
      floor.rotation.x = -Math.PI / 2; // Plat leggen
      floor.position.y = -0.5; // Zet de vloer onder de kubus
      floor.receiveShadow = true; 
      scene.add(floor);

      //create 15x15 instanced cubes with size 5 meter
      //this.addInstancedCubes(scene, 3, 5);

      // OrbitControls
      this.controls = new OrbitControls(camera, renderer.domElement);
      var controls = this.controls;
      controls.enableDamping = true;
      controls.dampingFactor = 0.05;
      // Zoominstellingen
      controls.zoomSpeed = 2; // Pas de zoom snelheid aan (de standaardwaarde is 1)
      controls.minDistance = 1; // Minimale afstand voor inzoomen
      controls.maxDistance = 4000; // Maximale afstand voor uitzoomen

      // Window resizen
      window.addEventListener('resize', () => {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      });

      // Animatie loop
      const animate = () => {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
      };

      animate();
    },
    centerModel(){
        this.$utils.centerScene(this.scene, true, true);
    }
    ,
    async getUrl() {
        const response = await fetch(`${this.$store.apiUrl}/geturl/${this.shortCode}`)
        .then(response => response.json())
        .then(data => {
          this.loadModel(data.url);
        })
        .catch(error => {
          console.error('Fout bij het ophalen van het glb model:', error);
        });
    },
    addCube(x,y,z){

      const geometry = new THREE.BoxGeometry(100, 100, 100); 
      const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
      const cube = new THREE.Mesh(geometry, material);

      const pos = new THREE.Vector3(x,y,z);
      cube.position.copy(pos);

      this.scene.add(cube);
    },
    updateMaterials() {

      this.scene.remove(this.ambientLight);
      this.scene.add(this.ambientForShadow);
      this.scene.add(this.sunlight);

      const textureLoader = new THREE.TextureLoader();
     // const normalMap = textureLoader.load('images/NormalMap.png'); // Load your normal map texture

      this.scene.traverse((object) => {
          if (object.isMesh && object.name !== 'floor' && object.name !== "Cubes") {
            object.material.roughness = 0.1;
            object.castShadow = true;
            object.receiveShadow = true;
            // console.log(`Materialen van ${object.name}:`, object.material);
          }
      });
    
    },
    downloadTextures(){

      var counter = 0;

      this.scene.traverse((child) => {
        counter++;

        if (child.isMesh && child.material.map) {
          const texture = child.material.map;
          const image = texture.image;

          // Maak een canvas element om de image te renderen
          const canvas = document.createElement('canvas');
          canvas.width = image.width;
          canvas.height = image.height;
          const context = canvas.getContext('2d');

          // Render de afbeelding in de canvas
          context.drawImage(image, 0, 0);

          // Zet de canvas om naar een data URL (afbeelding)
          const dataURL = canvas.toDataURL('image/png');

          // Maak een download link voor de afbeelding
          const link = document.createElement('a');
          link.href = dataURL;
          link.download = `texture${counter}.png`; // De naam van de gedownloade afbeelding
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }

      });
    },
    addInstancedCubes(scene, gridSize, cubeSize) {
        // Eén geometrie en materiaal voor alle kubussen
        const geometry = new THREE.BoxGeometry(cubeSize, cubeSize * 100, cubeSize);
        const material = new THREE.MeshStandardMaterial({ color: 0x0077ff });

        // Maak een InstancedMesh met het totale aantal kubussen
        const count = gridSize * gridSize;
        const instancedMesh = new THREE.InstancedMesh(geometry, material, count);
        instancedMesh.name = "Cubes";

        // Bereken posities voor elk exemplaar en pas ze toe
        let index = 0;
        for (let x = -gridSize / 2; x < gridSize / 2; x++) {
          for (let z = -gridSize / 2; z < gridSize / 2; z++) {
            const matrix = new THREE.Matrix4();
            matrix.setPosition(x * cubeSize * 2, cubeSize /2, z * cubeSize * 2);
            instancedMesh.setMatrixAt(index, matrix);  // Wijs de transformatie matrix toe aan elke instantie
            index++;
          }
        }

        instancedMesh.castShadow = true; // Laat het hele InstancedMesh schaduwen werpen
        instancedMesh.receiveShadow = true; // Laat het schaduwen ontvangen
        
        scene.add(instancedMesh);
    },
    loadModel(url_glb){
            // GLTFLoader om het model te laden
            const loader = new GLTFLoader();
            let cubeCounter = 1;

            loader.load(
                url_glb, // URL naar het GLB-bestand
                (gltf) => {

                    var model = gltf.scene;
                    model.name = "glb model";

                    //this.debugScene(model);
                    //    var center = this.$utils.getVerticesCenter(model);
                    //   this.$utils.centerScene(model, center);

                    // console.log("-------------------")
                    //this.debugScene(model);

                    this.scene.add(model);

                  });
    },
    debugScene(scene) {
      const totalBoundingBox = new THREE.Box3();

      scene.traverse((child) => {
        if (child.isMesh && child.name !== "floor" && child.name !== "Cubes") {
          console.log(`Name: ${child.name} Type: ${child.type}, Visible: ${child.visible} Transparant: ${child.material.transparent} Position: (${child.position.x}, ${child.position.y}, ${child.position.z})`);
          // console.log(`Rotation (rad): (${child.rotation.x}, ${child.rotation.y}, ${child.rotation.z})`);
          // console.log(`Scale: (${child.scale.x}, ${child.scale.y}, ${child.scale.z})`);
          child.geometry.computeBoundingBox();
          const boundingBox = child.geometry.boundingBox.clone().applyMatrix4(child.matrixWorld);
          totalBoundingBox.union(boundingBox);
        }
      });

      const size = new THREE.Vector3();
      totalBoundingBox.getSize(size);

      const width = size.x;
      const height = size.y;
      const depth = size.z;

      // Bereken het midden van de totale bounding box
      const center = totalBoundingBox.getCenter(new THREE.Vector3());
      console.log(`Total Dimensions: Width: ${width}m, Height: ${height}m, Depth: ${depth}m`);
      console.log(`Center of Bounding Box: (${center.x}, ${center.y}, ${center.z})`);
    }
    ,
    calculateUVs(geometry) {
      const position = geometry.attributes.position.array;
      const uvs = [];

      for (let i = 0; i < position.length; i += 3) {
          // Eenvoudige UV-projectie (bijvoorbeeld orthografisch)
          const x = position[i];
          const z = position[i + 2]; // Gebruik Z voor 2D projectie

          // Normeer de waarden naar een [0, 1] bereik
          uvs.push((x + 1) / 2); // X naar [0, 1]
          uvs.push((z + 1) / 2); // Z naar [0, 1]
      }
      return uvs;
    }
  },
  beforeDestroy() {

}
};
</script>

<style scoped>
.three-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
</style>
